import { ChangeEvent } from 'react';
import { TextField as MuiTextField, makeStyles } from '@material-ui/core';

type StyleProps = {
  gutterBottom: boolean;
};

const useStyles = makeStyles((theme) => ({
  textField: ({ gutterBottom }: StyleProps) =>
    gutterBottom
      ? {
          marginBottom: theme.spacing(2),
        }
      : {},
}));

type Props = {
  value: string;
  onChange(newValue: string): void;
  name: string;
  label: string;
  type?: string | undefined;
  multiline?: boolean;
  gutterBottom?: boolean;
  readonly?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
};

// useInputStateを前提としない「素」の入力フィールド
// デザインとpropsを統一することを主目的とする
export const TextFieldBare = ({
  value,
  onChange,
  name,
  label,
  type,
  multiline = false,
  gutterBottom = true,
  readonly = false,
  variant = 'outlined',
}: Props): JSX.Element => {
  const classes = useStyles({ gutterBottom });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <MuiTextField
      value={value}
      onChange={handleChange}
      name={name}
      label={label}
      type={type}
      fullWidth
      multiline={multiline}
      disabled={readonly}
      minRows={multiline ? 5 : 1}
      variant={variant}
      InputProps={{
        style: { backgroundColor: 'white' },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ 'aria-label': label }}
      className={classes.textField}
    />
  );
};
