import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { formatDate, FormatDateOption } from 'utils/date';

type StyleProps = {
  gutterBottom: boolean;
};

const useStyles = makeStyles((theme) => ({
  picker: ({ gutterBottom }: StyleProps) =>
    gutterBottom
      ? {
          marginBottom: theme.spacing(2),
        }
      : {},
}));

type Props = {
  value: Date | null;
  onChange(newValue: Date | null): void;
  name: string;
  label: string;
  gutterBottom?: boolean;
  inputVariant?: 'filled' | 'outlined' | 'standard' | undefined;
};

const dateLabelFunc = (date: Date | null, invalidLabel: string) =>
  invalidLabel || formatDate(date, FormatDateOption.full);

// useInputStateを前提としない「素」の入力フィールド
// デザインとpropsを統一することを主目的とする
export const DateFieldBare = ({
  value,
  onChange,
  name,
  label,
  gutterBottom = true,
  inputVariant = 'outlined',
}: Props): JSX.Element => {
  const classes = useStyles({ gutterBottom });

  const handleChange = (newValue: Date | null) => {
    onChange(newValue);
  };

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      name={name}
      label={label}
      fullWidth
      clearable
      labelFunc={dateLabelFunc}
      autoOk
      inputVariant={inputVariant}
      InputProps={{
        style: { backgroundColor: 'white' },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ 'aria-label': label }}
      className={classes.picker}
    />
  );
};
