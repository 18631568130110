import { ChangeEvent } from 'react';
import {
  TextField as MuiTextField,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

type StyleProps = {
  gutterBottom: boolean;
};

const useStyles = makeStyles((theme) => ({
  textField: ({ gutterBottom }: StyleProps) =>
    gutterBottom
      ? {
          marginBottom: theme.spacing(2),
        }
      : {},
}));

export type SelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  value: string;
  onChange(newValue: string): void;
  name: string;
  label: string;
  options: SelectOption[];
  gutterBottom?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
};

export const SelectFieldBare = ({
  value,
  onChange,
  name,
  label,
  options,
  gutterBottom = true,
  variant = 'outlined',
}: Props): JSX.Element => {
  const classes = useStyles({ gutterBottom });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <MuiTextField
      select
      value={value}
      onChange={handleChange}
      name={name}
      label={label}
      fullWidth
      variant={variant}
      InputProps={{
        style: { backgroundColor: 'white' },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ 'aria-label': label }}
      className={classes.textField}
    >
      {options.map(({ label, value, disabled = false }) => (
        <MenuItem key={value} value={value} disabled={disabled}>
          {label}
        </MenuItem>
      ))}
    </MuiTextField>
  );
};
