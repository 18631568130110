import { InputState } from 'hooks/useInputState';
import { DateFieldBare } from './DateFieldBare';

type Props = {
  inputState: InputState<unknown>;
  name: string;
  label: string;
  gutterBottom?: boolean;
};

export const DateField = ({
  inputState,
  name,
  label,
  gutterBottom = true,
}: Props): JSX.Element => {
  const [getValue, setValue] = inputState;

  const handleChange = (newValue: Date | null) => {
    setValue(name, newValue);
  };

  return (
    <DateFieldBare
      value={getValue(name) as Date | null}
      onChange={handleChange}
      name={name}
      label={label}
      gutterBottom={gutterBottom}
    />
  );
};
