import { InputState } from 'hooks/useInputState';
import { TextFieldBare } from './TextFieldBare';

type Props = {
  inputState: InputState<unknown>;
  name: string;
  label: string;
  type?: string | undefined;
  multiline?: boolean;
  gutterBottom?: boolean;
};

export const TextField = ({
  inputState,
  name,
  label,
  type,
  multiline = false,
  gutterBottom = true,
}: Props): JSX.Element => {
  const [getValue, setValue] = inputState;

  const handleChange = (newValue: string) => {
    setValue(name, newValue);
  };

  return (
    <TextFieldBare
      value={(getValue(name) as string) ?? ''}
      onChange={handleChange}
      name={name}
      label={label}
      type={type}
      multiline={multiline}
      gutterBottom={gutterBottom}
    />
  );
};
