import type { ReactNode } from 'react';
import {
  Button,
  CircularProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      display: 'inline',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

type Props = {
  disabled?: boolean;
  waiting?: boolean;
  children: ReactNode;
};

export const SubmitButton = ({
  disabled = false,
  waiting = false,
  children,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        disableElevation
        type="submit"
        variant="contained"
        color="primary"
        disabled={disabled || waiting}
      >
        {children}
      </Button>
      {waiting && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
};
